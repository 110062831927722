import React, { Component } from "react";

// import Slider from "react-slick";

// import {get_url_params} from "../utilities/deep_link.js";
// import utility_data from "../utilities/browser_information.js";
import { request_page } from "../../components/data/fetch_data.js";

import PageBreak from "../repeating_elements/page_break.js";
import ProjectItem from "../repeating_elements/Project_Item.js";
import DropDown from "../Work/Drop_Down.js";
import LazyLoad from "../repeating_elements/Lazy_Load.js";

import {dispatch_vp_update} from "../utilities/event-viewport-update.js";
// import ArrowHead from "../../assets/svg/arrow-head.svg";
import {get_url_params} from "../utilities/deep_link.js";

export default class Work_Display extends Component {
	constructor(props){
		super(props);
		let activeFilter = 4;
		let activeIndustry = false;
		this.in_view =  false
		this.default_catagory = activeFilter
		this.state = {
			grid_shown: false,
			fade_class: "",
			work_posts: this.props.work_posts,
			active_filter: activeFilter,
			active_industry: activeIndustry,
			active_service: false,
			filter_highlight_position: []
		};
		this.count = 0;
		this.show_timeout = null;
		this.request_page = request_page.bind(this);
		this.make_grid = this.make_grid.bind(this);
		this.update_industry = this.update_industry.bind(this);
		this.update_service = this.update_service.bind(this);
		this.change_grid = this.change_grid.bind(this);
		this.make_filter = this.make_filter.bind(this);
		this.hide_grid = this.hide_grid.bind(this);
		this.show_grid = this.show_grid.bind(this);
		this.filter_posts = this.filter_posts.bind(this);
		this.create_grid_item_1 = this.create_grid_item_1.bind(this);
		this.create_grid_item_2 = this.create_grid_item_2.bind(this);
		this.create_grid_item_3 = this.create_grid_item_3.bind(this);
		this.create_grid_item_4 = this.create_grid_item_4.bind(this);
		this.create_grid_item_5 = this.create_grid_item_5.bind(this);
		this.create_grid_item_6 = this.create_grid_item_6.bind(this);
		this.create_empty_grid = this.create_empty_grid.bind(this);
	}
	componentDidMount(){
		let params = get_url_params();

		this.setState({
			active_filter: this.props.filterMap[params.industry] || 4,
			active_industry: this.props.filterMap[params.industry] || 4
		})

		if(!this.state.grid_shown){
			if (document.documentMode || /Edge/.test(navigator.userAgent)) {
				this.show_timeout = setTimeout(()=>{
					this.show_grid();
				}, 2000);
				return null
			}
			else dispatch_vp_update()
			this.show_timeout = setTimeout(()=>{
				this.show_grid();
			}, 2000);
		}

		// this.request_page("cats","categories")
		// this.request_page("work_posts","posts?categories="+( params.industry ? this.props.filterMap[params.industry] : this.state.active_filter)+"&per_page=100")
	}
	update_industry(id){
		this.setState({
			active_industry: id
		})
		this.change_grid()
	}
	update_service(id, element){
		let container = document.getElementsByClassName("work-display")[0].getBoundingClientRect();
		this.change_grid()
		if(element){
			let active_position = document.getElementById(element.key).getBoundingClientRect();
			this.setState({
				active_service: id,
				filter_highlight_position: [(active_position.left - container.left),active_position.width]
			});
		}
	}
	change_grid(){
		clearTimeout(this.show_timeout);
		this.hide_grid();
		setTimeout(()=>{
			if(this.state.active_industry && this.state.active_service){
				this.request_page("work_posts","posts?categories="+this.state.active_industry+"+"+this.state.active_service+"&per_page=100", true)
			} else if(this.state.active_industry){
				this.request_page("work_posts","posts?categories="+this.state.active_industry+"&per_page=100")
			} else if(this.state.active_service){
				this.request_page("work_posts","posts?categories="+this.state.active_service+"&per_page=100")
			} else{
				this.request_page("work_posts","posts?categories="+this.default_catagory+"&per_page=100")
			}
			setTimeout(this.show_grid, 2500)
		},400);
	}
	make_grid(){
		var project_list = this.state.work_posts;
		var amount_of_projects = project_list.length;
		var grid = [];
		var alternate = 0;
		var alternate2 = 0;
		var alternate3 = 0;
		var i = 0;
		if(amount_of_projects === 0){
			grid.push( this.create_empty_grid() );
		} else{
			while(i < amount_of_projects){
				if((amount_of_projects - i) % 3 === 0){
					if(alternate % 2){
						if(alternate2 % 2){
							grid.push( this.create_grid_item_3() );
						} else{
							grid.push( this.create_grid_item_4() );
						}
						alternate2++;
					} else{
						if(alternate3 % 2){
							grid.push( this.create_grid_item_1() );
						} else{
							grid.push( this.create_grid_item_2() );
						}
						alternate3++;
					}
					i = i + 3;
					alternate++;
				} else if((amount_of_projects - i) % 2 === 0){
					grid.push( this.create_grid_item_5() );
					i = i + 2;
				} else{
					grid.push( this.create_grid_item_6() );
					i = i + 1;
				}
			}
		}
		this.count = 0;
		return(
			<div className="slide row work-display-grid">
				{ grid }
			</div>
		);
	}
	create_grid_item_1(){
		return(this.state.work_posts[this.count] ?
			<div className="component row">
				<div className="project-column grid-m-6 grid-t-8 grid-d-8">
					<LazyLoad>
						<ProjectItem project={this.state.work_posts[this.count++]} tall={true} classes="project-list-1"  />
					</LazyLoad>
				</div>
				<div className="project-column grid-m-6 grid-t-8 grid-d-8">
					<LazyLoad>
						<ProjectItem project={this.state.work_posts[this.count++]} classes="project-list-2"  />
						<ProjectItem project={this.state.work_posts[this.count++]} classes="project-list-3"  />
					</LazyLoad>
				</div>
			</div>
		: null)
	}
	create_grid_item_2(){
		return(this.state.work_posts[this.count] ?
			<div className="component row">
				<div className="project-column grid-m-6 grid-t-8 grid-d-8">
					<LazyLoad>
						<ProjectItem project={this.state.work_posts[this.count++]} classes="project-list-1"  />
						<ProjectItem project={this.state.work_posts[this.count++]} classes="project-list-2"  />
					</LazyLoad>
				</div>
				<div className="project-column grid-m-6 grid-t-8 grid-d-8">
					<LazyLoad>
						<ProjectItem project={this.state.work_posts[this.count++]} tall={true} classes="project-list-3"  />
					</LazyLoad>
				</div>
			</div>
		: null)
	}
	create_grid_item_3(){
		return(
			<div className="component row">
				<div className="project-column grid-m-6 grid-t-8 grid-d-8">
					<LazyLoad>
						<ProjectItem project={this.state.work_posts[this.count++]} classes="project-list-1"  />
					</LazyLoad>
				</div>
				<div className="project-column grid-m-6 grid-t-8 grid-d-8">
					<LazyLoad>
						<ProjectItem project={this.state.work_posts[this.count++]} classes="project-list-2"  />
					</LazyLoad>
				</div>
				<div className="project-column grid-m-6 grid-t-16 grid-d-16">
					<LazyLoad>
						<ProjectItem project={this.state.work_posts[this.count++]} wide={true} classes="project-list-3"  />
					</LazyLoad>
				</div>
			</div>
		)
	}
	create_grid_item_4(){
		return(
			<div className="component row">
				<div className="project-column grid-m-6 grid-t-16 grid-d-16">
					<LazyLoad>
						<ProjectItem project={this.state.work_posts[this.count++]} wide={true} classes="project-list-1"  />
					</LazyLoad>
				</div>
				<div className="project-column grid-m-6 grid-t-8 grid-d-8">
					<LazyLoad>
						<ProjectItem project={this.state.work_posts[this.count++]} classes="project-list-2"  />
					</LazyLoad>
				</div>
				<div className="project-column grid-m-6 grid-t-8 grid-d-8">
					<LazyLoad>
						<ProjectItem project={this.state.work_posts[this.count++]} classes="project-list-3"  />
					</LazyLoad>
				</div>
			</div>
		)
	}
	create_grid_item_5(){
		return(
			<div className="component row">
				<div className="project-column grid-m-6 grid-t-8 grid-d-8">
					<LazyLoad>
						<ProjectItem project={this.state.work_posts[this.count++]}  classes="project-list-1" />
					</LazyLoad>
				</div>
				<div className="project-column grid-m-6 grid-t-8 grid-d-8">
					<LazyLoad>
						<ProjectItem project={this.state.work_posts[this.count++]} classes="project-list-2"  />
					</LazyLoad>
				</div>
			</div>
		)
	}
	create_grid_item_6(){
		return(
			<div className="component row">
				<div className="project-column grid-m-6 grid-t-16 grid-d-16">
					<LazyLoad>
						<ProjectItem project={this.state.work_posts[this.count++]} wide={true} classes="project-list-1"  />
					</LazyLoad>
				</div>
			</div>
		)
	}
	create_empty_grid(){
		return <div className="component row">
			<div className="grid-m-6 grid-t-16 grid-d-16">
				<h2>No projects found.</h2>
				<p>Those filters yeilded no results. Please try a different filter combination.</p>
			</div>
		</div>;
	}
	filter_posts(value){
		this.setState({
			active_filter: value
		});
	}
	make_filter(){
		var parent = this;
		var filters = this.props.data.industries_filter;
		var filter_elements = [];
		function add_all_post(){
			var element = <li id="filter-industry-all" key="filter-industry-all" className={ !parent.state.active_service ? "active" : "" }>
				<button value="4" onClick={()=>{parent.update_service(false, element)}}></button>
				All
			</li>;
			filter_elements.push(element);
		}
		if(filters.length > 1){
			add_all_post();
			filters.forEach(function(filter){
				var element = <li id={"filter-industry-"+filter.industry_catagory.slug} key={"filter-industry-"+filter.industry_catagory.slug} className={ filter.industry_catagory.term_id === parent.state.active_service ? "active" : "" }>
					<button value={filter.industry_catagory.term_id} onClick={()=>{parent.update_service(filter.industry_catagory.term_id, element)}}></button>
					{filter.industry_catagory.name}
				</li>;
				filter_elements.push(element)
			})
			return filter_elements;
		}
	}
	hide_grid(){
		clearTimeout(this.show_timeout)
		this.setState({
			grid_shown: false,
			fade_class: ""
		})
	}
	show_grid(){
		this.setState({
			grid_shown: true,
			fade_class: " grid-loaded"
		})
	}
	render() {
		const in_view = this.in_view ? " in-view" : "";
		if(this.state.work_posts){
			
			var highlight_styles = {
				left: ( this.state.filter_highlight_position[0] === 0 ? 20 : this.state.filter_highlight_position[0] - 60 || 20 )+"px" ,
				width: ( this.state.filter_highlight_position[1] || 18 )+"px"
			};
			return(
				<div className={"work-display grid" + in_view}>
					<div className="row project-filter-container options">
						<div className="grid-m-hide grid-t-hide grid-d-12 filter-lists">
							<ul className="project-filter">
								{ this.make_filter() }
							</ul>
							<div className="highlight" style={highlight_styles}></div>
						</div>
						<div className="grid-m-6 grid-t-12 grid-d-hide filter-drop-down">
							<DropDown default="View by service" options={this.props.data.industries_filter} active={this.state.active_service} onChange={this.update_service} />
						</div>
						<div className="grid-m-6 grid-t-4 grid-d-4 industy-drop-down text-align-right">
							<DropDown default="View by industry" options={this.props.data.industries} active={this.state.active_filter} onChange={this.update_industry} />
						</div>
						<div className="page-break grid-m-hide grid-t-16 grid-d-16"></div>
					</div>
					<div className={"grid-contain row"+this.state.fade_class}>
						{this.make_grid()}
					</div>
				</div>
			);
		}
		return(
			<div className={"work-display grid" + in_view}>
				<PageBreak />
				<div className={"grid-contain row"}>
					<div className="row work-display-grid">
						<div className="project-column grid-m-6">
							<div className="project-item tall">
								<div className="item-imagery">
									{/* <div className="frame"></div> */}
									<div className="fill"></div>
									<div className="thumbnail"></div>
								</div>
							</div>
						</div>
						<div className="project-column grid-m-6">
							<div className="project-item tall">
								<div className="item-imagery">
									{/* <div className="frame"></div> */}
									<div className="fill"></div>
									<div className="thumbnail"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="load-more-container grid-m-6 grid-t-hide grid-d-hide">
					<span className="button">Load More</span>
				</div>
			</div>
		);
		// return <div>ok</div>
	}
}

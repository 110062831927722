import React, { Component } from "react";

import Slider from "react-slick";
import { animateScroll } from 'react-scroll';
import { ArrowLink } from "../buttons/Arrow_Link.js";
import utility_data from "../utilities/browser_information.js";
import link_realignment from "../utilities/link_realignment.js";
import BackgroundImage from "../repeating_elements/Background-Image.js";
import EnterHeader from "../repeating_elements/Enter_Header.js";
import ArrowHead from "../../assets/svg/arrow-head.svg";

import {get_url_params} from "../utilities/deep_link.js";

import detectObjectFit from '../utilities/detectObjectFit'
import { makeObjectFit } from '../polyfill/object-fit'

// Or Access Link,Element,etc as follows

export default class WorkPanoramicHero extends Component {
	constructor(props){
		super(props);
		this.state = {
			height: 700,
			active_industry: false
		};
		this.update_height = this.update_height.bind(this);
		this.render_slides = this.render_slides.bind(this);
		this.make_arrow_button = this.make_arrow_button.bind(this);
		this.create_slide_copy = this.create_slide_copy.bind(this);
	}
	shouldComponentUpdate(nextProps, nextState) {
		return true;
	}
	update_height(){
		setTimeout(function(){
			this.setState({height: utility_data().browser_height})
		}.bind(this), 100)
	}
	scroll_to_bottom = () => {
		if (window.innerHeight >= 640) animateScroll.scrollTo(utility_data().browser_height - 200);
		else animateScroll.scrollTo(utility_data.browser_height);
	}
	make_arrow_button(slide){
		var element;
		if(slide.cta){
			element = <ArrowLink title={slide.cta} url={link_realignment(slide.link) || "/"} />
		}
		return element;
	}
	position = 0
	contents = []
	handlePanoramicStart = e => {
		this.position = e.touches[0].clientX
	}
	handlePanoramicMove = e => {
		if ((e.touches[0].clientX - this.position >= 10 || e.touches[0].clientX - this.position <= -10) && !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
			this.contents.map(content => content ? content.classList.add('invisible') : null)
		}
	}
	handlePanoramicEnd = e => {
		setTimeout(() => {
			this.contents.map(content => content ? content.classList.remove('invisible') : null)
		}, 500)
	}
	positionX = 0
	positionY = 0
	componentDidMount(){
		this.update_height();
		const panoramic = this.panoramic
		if(!detectObjectFit()){
			// $html.classList.add('css-objectFit');
			makeObjectFit()
		}

		this.setState({
			height: utility_data().browser_height
		})

		const params = get_url_params()
		if(params.industry){
			this.setState({
				active_industry: this.props.filterMap[params.industry]
			})
		}

		window.addEventListener("resize", this.update_height);

		this.panoramic.addEventListener('touchstart', e => {
			this.positionX = e.touches[0].clientX
			this.positionY = e.touches[0].clientY
		})
		this.panoramic.addEventListener('touchmove', e => {
			Math.abs(e.touches[0].clientX - this.positionX) > Math.abs(e.touches[0].clientY - this.positionY) ?
				document.documentElement.classList.add('overscroll-locked') :
				document.documentElement.classList.remove('overscroll-locked')
		})
		this.panoramic.addEventListener('touchend', e => {
			setTimeout(() => document.documentElement.classList.remove('overscroll-locked'), 500)
		})
		setTimeout(() => {
			panoramic.classList.add('allset')
		}, 500)
	}
	componentWillUnmount(){
		window.removeEventListener("resize", this.update_height);
		document.documentElement.classList.remove('overscroll-locked')
	}
	destroyed() {
		this.panoramic.remove('touchstart', e => {
			this.positionX = e.touches[0].clientX
			this.positionY = e.touches[0].clientY
		})
		this.panoramic.remove('touchmove', e => {
			Math.abs(e.touches[0].clientX - this.positionX) > Math.abs(e.touches[0].clientY - this.positionY) ?
				document.documentElement.classList.add('overscroll-locked') :
				document.documentElement.classList.remove('overscroll-locked')
		})
		this.panoramic.remove('touchend', e => {
			setTimeout(() => document.documentElement.classList.remove('overscroll-locked'), 500)
		})
	}
	create_slide_copy(slide){
		var element = [];
		if(slide.copy){
			element.push(<h3 className="styling-eyebrow"><span>{slide.title}</span></h3>)
			element.push(<EnterHeader className="styling-header-2">{slide.copy}</EnterHeader>)
		} else{
			element.push(<h3 className="styling-eyebrow"><span> </span></h3>)
			element.push(<EnterHeader className="styling-header-2">{slide.title}</EnterHeader>)
		}
		return element;
	}
	render_slides(){
		if(this.props.slides){
			let matchingPosts = this.props.slides[0].acf["service-slides"].filter((i)=>{
				if (this.state.active_industry) {
					if(i.service === this.state.active_industry) {
						return true
					}
					else return false;
				}
				else return true
			})
			if(matchingPosts.length < 1){
				matchingPosts = this.props.slides[0].acf.slides;
			}
			return matchingPosts.map( (slide) =>
				<div className="slide" key={slide.title}>
					<div className="image">
						<BackgroundImage videoURL={slide.vimeo_url} url={ slide.desktop_image ? slide.desktop_image.sizes.large : "" }  cover="disabled" alt={slide.title}></BackgroundImage>
					</div>
					<div className="content" ref={c => this.contents.push(c)}>
						<div className="grid">
							<div className="grid-m-5 grid-t-10 grid-d-10">
								{this.create_slide_copy(slide)}
								<div className="arrow-container"><div>{ this.make_arrow_button(slide) }</div></div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
	render(){
		const viewed = this.props.views % this.props.slides[0].acf.slides.length || 0;
		const height = this.state.height;
		// let data = this.props.slides[0].acf.slides;
		var settings = {

			arrows: false,
			dots: true,
			infinite: true,
			speed: 500,
			slide: ".slide",
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: false,
			initialSlide: viewed
		};
		return <div className="panoramic-hero" ref={c => this.panoramic = c} onTouchStart={e => this.handlePanoramicStart(e)} onTouchMove={e => this.handlePanoramicMove(e)} onTouchEnd={e => this.handlePanoramicEnd(e)}>
			<div className="slider-container" style={{height: height + "px"}}>
				<Slider {...settings}>
					{this.render_slides() }
				</Slider>
				<div className="panoramic-hero-buttons"></div>
			</div>
			<div className="drop-arrow">
				<button aria-label="Scroll Down" onClick={()=>{this.scroll_to_bottom()}}></button>
				<div className="arrow">
					<div className="shaft"></div>
					<ArrowHead />
				</div>
			</div>
		</div>;
	}
}

import React, { Component } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import PageTransition from 'gatsby-plugin-page-transitions';
import PageBreak from "../components/repeating_elements/page_break.js";
import {get_url_params} from "../components/utilities/deep_link.js";

import Navigation from "../components/Header/Navigation.js";
import PanoramicHero from "../components/Global/Panoramic_Hero.js";
import WorkPanoramicHero from "../components/Work/Work_Hero.js";
import WorkDisplay from "../components/Work/Work_Display.js";
import GetInTouchFooter from "../components/Global/Get_In_Touch_Footer.js";
import Footer from "../components/Footer/Footer.js";
import WorkWithUs from "../components/Global/Work_With_Us_Button.js";
import GDPR from '../components/Global/GDPR';
// import Loader from '../components/Global/Loader';
import SEO from '../components/seo.js';


class Work extends Component {
	constructor(props){
		super(props);
		this.state = {
			isLoaded: this.props.pageContext.isLoaded,
			isLazyLoaded: this.props.pageContext.isLazyLoaded,
			isReady: this.props.pageContext.isReady,
			data: this.props.pageContext.data,
			cookieBanner: this.props.pageContext.cookieBanner,
			headerLinks: this.props.pageContext.header,
			footerLinks: this.props.pageContext.footer,
			posts: this.props.pageContext.posts,
			urlParams: []
		}
	}
	componentDidMount() {
		this.setState({ urlParams: get_url_params() });
		
	}
	render() {
		const filterMap = {
			"ecommerce": 24,
			"fashion": 25,
			"hospitality": 55,
			"lifestyle": 28,
			"luxury": 27,
			"non-profit": 29,
			"publishing-media": 30,
			"tech": 57,
			"new-projects": 70
		};
		let page_ready = this.state.isLoaded && this.state.isReady ? "page-ready page-loaded show-text" : "page-loading";
		if(this.state.isLoaded){

			return (
				<ParallaxProvider><PageTransition>
				<GDPR data={this.state.cookieBanner} />
				
				<SEO
					title={this.state.data[0].yoast_meta.yoast_wpseo_title}
					description={this.state.data[0].yoast_meta.yoast_wpseo_metadesc}
				/>
					<div className="site-contain">
						<Navigation links={this.state.headerLinks} />
          		 		<div className="page-main">
				 			<WorkWithUs />
				 			<div className={"page work-page " + page_ready}>
				 			{ this.state.urlParams ?
				 				<div>
									 { this.state.urlParams.industry ? <WorkPanoramicHero slides={this.state.data} urlParams={this.state.urlParams} filterMap={filterMap} /> : <PanoramicHero slides={this.state.data} urlParams={this.state.urlParams} filterMap={filterMap} /> }
									<WorkDisplay data={this.state.data[0].acf} urlParams={this.state.urlParams} filterMap={filterMap} work_posts={this.state.posts} />
				 					<PageBreak />
				 					<GetInTouchFooter data={this.state.data[0]} />
				 					<Footer links={this.state.footerLinks} />
				 				</div>
				 			: null }
				 			</div>
				 		</div>
				</div>
				</PageTransition></ParallaxProvider>
			)
		} else{

			return (
				<div></div>
			)
		}
	}
}

export default Work;
